import React, { Component } from "react";
import axios from "@/axios";

class VendorRegister extends Component {
    constructor() {
        super();
        this.state = {
            message: "",
            companyName: "",
            phoneNumber: "",
            email: "",
            loading: false,
            success: false,
        };
    }

    handleChange = (event) => {
        const { value, name } = event.target;
        if (name === "phoneNumber") {
            if (!/^$|^[0-9]+$/.test(event.target.value)) {
                return;
            }
        }
        this.setState({
            [name]: value,
        });
    };

    register = (event) => {
        event.preventDefault();

        if (!this.state.companyName) {
            this.setState({ message: "Please enter your company name." });
            return;
        }
        if (!this.state.email) {
            this.setState({ message: "Please enter your email." });
            return;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
            this.setState({ message: "Invalid email." });
            return;
        }
        if (!this.state.phoneNumber) {
            this.setState({ message: "Please enter your phone number." });
            return;
        }
        if (!(this.state.phoneNumber.length === 10)) {
            this.setState({ message: "Invlaid phone number." });
            return;
        }

        try {
            this.setState({ message: "", loading: true });
            axios
                .post("/api/v4/vendor/onboarding/register", {
                    email: this.state.email,
                    phoneNumber: this.state.phoneNumber,
                    companyName: this.state.companyName,
                })
                .then((res) => {
                    if (res.data) {
                        if (res.data.status !== 1) {
                            this.setState({
                                message: res.data.message,
                                loading: false,
                            });
                            return;
                        }

                        this.setState({
                            message:
                                "You have successfully applied for Nimbuzz Pay to accept payments in your business. To get started, please refer to our API documentation for detailed instructions on integrating with Nimbuzz services. We appreciate your interest, and our team will contact you shortly with further information.",
                            loading: false,
                            success: true,
                        });
                    }
                });
        } catch (error) {
            this.setState({ message: "Something went wrong." });
        }
    };

    render() {
        return (
            <div className="hero-banner">
                <div className="hero-silhouette" style={{ zIndex: "0" }}></div>
                <div className="back-decorate"></div>
                <div className="hero-bannner-content">
                    <div className="container">
                        <div className="login-modal login-box">
                            <div className="modal-header">
                                <h5 className="font-weight-bold" style={{ color: "#FF900A" }}>
                                    Apply to become a Nimbuzz eSIM Merchant.
                                </h5>
                            </div>
                            <div className="modal-body">
                                {this.state.message.length > 0 && (
                                    <div className="show-message">{this.state.message}</div>
                                )}
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8">
                                        {this.state.success && (
                                            <div className="mb-2">
                                                <a
                                                    className="font-weight-bold border p-1 rounded"
                                                    href="/vendor-api-docs"
                                                >
                                                    View documentation
                                                </a>
                                            </div>
                                        )}
                                        {!this.state.success && (
                                            <div>
                                                <form>
                                                    <div className="form-group">
                                                        {this.state.companyName && (
                                                            <label className="font-weight-light ml-1">
                                                                Company Name
                                                            </label>
                                                        )}
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Company Name"
                                                            required
                                                            name="companyName"
                                                            value={this.state.companyName}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        {this.state.email && (
                                                            <label className="font-weight-light ml-1">
                                                                Email
                                                            </label>
                                                        )}
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            value={this.state.email}
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">+977</div>
                                                        </div>
                                                        <input
                                                            className="form-control"
                                                            type="tel"
                                                            pattern="[0-9]+"
                                                            value={this.state.phoneNumber}
                                                            maxLength={10}
                                                            placeholder="Enter Contact Number"
                                                            name="phoneNumber"
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <button
                                                        className="btn btn-danger w-100 btn-block"
                                                        onClick={this.register}
                                                        type="submit"
                                                        disabled={this.state.loading}
                                                    >
                                                        Apply
                                                    </button>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VendorRegister;
